import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from "../common/Link"
import SectionHeader from "../common/SectionHeader"
import { ChevronDoubleRightIcon } from "@heroicons/react/solid"

export default function ProfilesMenu() {
  const data = useStaticQuery(graphql`
    query {
      allWpPage(filter: { parentDatabaseId: { eq: 81 } }) {
        edges {
          node {
            id
            title
            uri
          }
        }
      }
    }
  `)
  const pages = data.allWpPage.edges
  return (
    <div className="space-y-4">
      <div className="grid gap-4">
        {pages.map((page, i) => {
          return <Page key={page.id} page={page} />
        })}
      </div>
    </div>
  )
}

function Page({ page }) {
  const { title, uri } = page.node
  return (
    <div className="group text-white">
      <Link
        to={uri}
        className="flex flex-row justify-between bg-black group-hover:bg-primary group-hover:text-white transition-all  p-6"
        activeClassName="bg-primary text-white"
      >
        <h3 className="text-2xl lg:text-3xl">{title}</h3>
        {/* <div className="w-8 text-primary group-hover:text-white">
          <ChevronDoubleRightIcon />
        </div> */}
      </Link>
    </div>
  )
}
